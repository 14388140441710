.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.modal-content {
  border-radius: 0 !important;
}
.btn-sm {
  font-size: 0.75rem !important;
}
