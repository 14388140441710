@import-normalize;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

*,
*:before,
*:after {
  font-family: "Poppins", sans-serif !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  word-wrap: break-word;
}
