$theme-colors: (
  "primary-blue": #002a5c,
  "secondary-blue": #008bb0,
  "custom-grey": #b94f7a,
  "secondary-red": #e31837,
  "secondary-yellow": #ffe498,
  "WhatsApp": #25d366,
  "Discord": #5865f2,
  "Messenger": #006aff,
  "WeChat": #09b83e,
  "Instagram": #c13584,
  "Telegram": #2aabee,
  "Slack": #000,
);

@import "~bootstrap/scss/bootstrap.scss";
